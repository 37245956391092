import axios from 'axios'

class HttpRequest {
  // 获取基础配置
  getInsideConfig() {
    const config = {
      timeout: 10000
    }
    return config
  }

  // 设定拦截器
  interceptors(instance) {
    // 请求拦截器
    instance.interceptors.request.use(
      config => {
        // 设置 post 请求头
        config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        return config
      },
      err => {
        return Promise.reject(err)
      }
    )

    // 响应拦截器
    instance.interceptors.response.use(
      res => {
        return Promise.resolve(res.data)
      },
      err => {
        return Promise.reject(err)
      }
    )
  }

  // 创建实例
  request(options) {
    const instance = axios.create()
    const newOptions = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance)
    return instance(newOptions)
  }

  // get 方法
  get(url, data) {
    const options = Object.assign(
      {
        method: 'get',
        url
      },
      data
    )
    return this.request(options)
  }

  // post 方法
  post(url, data) {
    return this.request({
      method: 'post',
      url,
      data
    })
  }
}

export default new HttpRequest()
